<template>
  <v-dialog
    v-model="show"
    width="1500"
  >
    <v-card>
      <v-card-title class="osis-title-bar">
        <div style="color: white">
          OSIS Documentation
        </div>

        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="white"
          icon
          @click="cancelDocu"
        >
          <v-icon> {{$icons.mdiClose}} </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-card>
          <v-card-text style="text-align: justify;">
            <v-card class="osis-card">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-img
                      class="mx-auto"
                      height="150"
                      width="150"
                      max-width="150"
                      :src="osisLogo"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="text-center"
                    sm="6"
                  >
                    <div class="osis-header">API Version</div>
                    <div class="osis-value">
                      {{ apiVersion.osis_api_version }}
                    </div>
                  </v-col>
                  <v-col
                    class="text-center"
                    sm="6"
                  >
                    <div class="osis-header">Frontend Version</div>
                    <div class="osis-value">
                      {{ frontendVersion }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

            </v-card>

            <div class="text-h5 font-weight-bold">
              About OSIS
            </div>
            <p class="text-body-1">
              The “Ocean Science Information System”, short <strong>OSIS</strong>, is a dynamic service, operated and developed by the GEOMAR Helmholtz Center for Ocean Research Kiel. At its core, OSIS serves as a hub where <strong>scientific activities are planned</strong> and <strong>metadata</strong> of these activities are <strong>managed</strong> and <strong>made accessible</strong> through publication. OSIS is also used for the <strong>internal exchange</strong> of data and metadata in the context of scientific activities.
            </p>

            <div class="text-h5  font-weight-bold mt-10">
              Access to OSIS
            </div>
            <p class="text-body-1">
              The OSIS WebApp can be accessed at <a href="https://osis.geomar.de">https://osis.geomar.de</a>. Public data and <strong>metadata</strong> can be viewed anonymously and without login. Access to <strong>internal information</strong> is restricted and requires a login. Note that an OSIS login isn't limited to GEOMAR employees; rather, it can be granted to anyone participating in scientific activities or individuals with a legitimate interest. If you require access, please reach out to <a href="mailto:osis@geomar.de">osis@geomar.de</a>.
            </p>
            <p class="text-body-1">
              In addition to the WebApp, OSIS provides all information in machine-readable format via a REST API. This allows for automated retrieval and management of data via the OSIS API. A comprehensive OSIS API documentation can be found at <a href="https://osis.geomar.de/api/apidocs">https://osis.geomar.de/api/apidocs</a>. Similar to the web application, access to protected information via the API needs an OSIS login, while public data remains freely accessible.
            </p>

            <div class="text-h5 font-weight-bold mt-10">
              Types of scientific activities
            </div>
            <p class="
            text-body-1">
              In OSIS, we categorize scientific activities into three main types, each with its own associated information:
            </p>

            <v-card
              class="osis-card"
              @click="goToExpeditions()"
            >
              <v-card-text>
                <div class="text-h6">
                  Expeditions
                </div>
                <p class="text-body-1">
                  Expeditions include both sea-based research voyages and land-based expeditions. A typical example of an expedition is a research cruise on a research vessel. Similarly, expeditions can also involve travels to terrestrial destinations for scientific investigations.
                </p>
              </v-card-text>
            </v-card>

            <v-card
              class="osis-card"
              @click="goToSimulations()"
            >
              <v-card-text>
                <div class="text-h6">
                  Numerical Simulations
                </div>
                <p class="text-body-1">
                  Numerical simulations are models of the earth system that investigate questions and make predictions. They consider individual components such as oceans, biogeochemistry and atmosphere or integrate multiple components. Ranging from regional to global scales, often nested, they offer invaluable insights into intricate environmental processes. </p>
              </v-card-text>
            </v-card>

            <v-card
              class="osis-card"
              @click="goToExperiments()"
            >
              <v-card-text>
                <div class="text-h6">
                  Experiments
                </div>
                <p class="text-body-1">
                  Experiments, whether conducted within laboratory settings or in natural environments such as open waters, involve the measurement and manipulation of environmental parameters. Questions are investigated by varying biotic and abiotic parameters, with replicates facilitating statistical evaluations to validate findings. </p>
              </v-card-text>
            </v-card>

            <div class="text-h5 font-weight-bold  mt-10">
              Further information on the activities
            </div>
            <p class="text-body-1">
              These three activities are coupled with further information in the form of assigned objects. The key objects associated with these activities are: </p>

            <v-card class="osis-card">
              <v-card-text>
                <div class="text-h6">
                  Persons
                </div>
                <p class="text-body-1">
                  Persons are assigned to the activities (<strong>Expeditions</strong>, <strong>Numerical Simulations</strong>, <strong>Experiments</strong>), e.g. as trip leaders or principal investigators. They are also linked to <strong>Deliverables</strong> and <strong>Community contexts</strong> (see below). </p>
              </v-card-text>
            </v-card>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h6">
                  Events
                </div>
                <p class="text-body-1">
                  Events are specific occurrences linked to <strong>Expeditions</strong>. For instance stops made by research vessels to deploy equipment.
                </p>
              </v-card-text>
            </v-card>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h6">
                  Deliverables
                </div>
                <p class="text-body-1">
                  Deliverables represent data promises associated to <strong>Events</strong> or <strong>Expeditions</strong> and a <strong>Person</strong>.
                </p>
              </v-card-text>
            </v-card>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h6">
                  Devices
                </div>
                <p class="text-body-1">
                  Devices which are applied for <strong>Events</strong>.
                </p>
              </v-card-text>
            </v-card>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h6">
                  Platforms
                </div>
                <p class="text-body-1">
                  Platforms are vehicles or large devices from which research activities are conducted during <strong>Expeditions</strong>. Typical examples are the different research vessels within the research fleet.
                </p>
              </v-card-text>
            </v-card>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h6">
                  Community Contexts
                </div>
                <p class="text-body-1">
                  Contextual links between <strong>Persons</strong> and research activities (<strong>Expeditions</strong>, <strong>Numerical Simulations</strong>, <strong>Experiments</strong>). This assignment can be used to sort research activities and manage personal rights within OSIS.
                </p>
              </v-card-text>
            </v-card>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h6">
                  Files and Links
                </div>
                <p class="text-body-1">
                  Data storage in OSIS encompass either direct file uploads or as links.
                  Links can be assigned to all research activities and <strong>Events</strong>, while
                  files are directly assigned to <strong>Expeditions</strong>, <strong>Experiments</strong> or <strong>Deliverables</strong>.
                </p>
              </v-card-text>
            </v-card>

            <div class="text-h5 font-weight-bold mt-10">
              Navigation in OSIS
            </div>
            <div class="text-h6">
              Home page
            </div>
            <p class="text-body-1">
              On the OSIS start page, the three scientific activities (<strong>Expeditions</strong>, <strong>Numerical Simulations</strong>, <strong>Experiments</strong>) are directly accessible. Recently processed activities are displayed in three tables, and clicking on any activity navigates you directly to the detailed view. A search field above the table enables quick access to the search in the overview table.
            </p>

            <div class="text-h6">
              Navigation menu
            </div>
            <p class="text-body-1">
              The navigation menu is located on the left-hand side of the header, denoted by <v-icon medium> {{$icons.mdiMenu}} </v-icon>. From here you can access overview tables for all activities and associated information. Please note: The content of the menu adjusts with your personal authorizations and login status.
            </p>

            <div class="text-h6">
              Overview table
            </div>
            <p class="text-body-1">
              Each of the three scientific activities (<strong>Expeditions</strong>, <strong>Numerical Simulations</strong>, <strong>Experiments</strong>) features an overview table, accessible via the start page or the navigation menu. The activities displayed depend on your personal authorizations and login status. Without logging in, only activities marked as 'Open Access' are visible. After logging in, activities from your own <strong>Community Contexts</strong> are also displayed.
            </p>

            <p class="text-body-1">
              The overview table can be searched for using filters. The type of filter tailored to each scientific activity (<strong>Expeditions</strong>, <strong>Numerical Simulations</strong>, <strong>Experiments</strong>):
            </p>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h7 font-weight-bold">
                  Expedition-Filters:
                </div>
                <ul class="text-body-1">
                  <li>Name: A free text field for the expedition name.</li>
                  <li>Platform: A selection list with ships/platforms.</li>
                  <li>Community Context: A selection list with contexts.</li>
                  <li>Person: Search for PI/ expedition leader.</li>
                </ul>
              </v-card-text>
            </v-card>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h7 font-weight-bold">
                  Numerical Simulations-Filters:
                </div>
                <ul class="text-body-1">
                  <li>Name: A free text field for the simulation name.</li>
                  <li>Description: Allows searching within the description text.</li>
                  <li>Community Context: A selection list of contexts.</li>
                  <li>Scopes: A selection list featuring spatial scales such as 'global', 'regional', 'nested'.</li>
                  <li>ES Components: A selection list featuring earth system components like 'land', 'ocean', 'atmos'.</li>
                </ul>
              </v-card-text>
            </v-card>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h7 font-weight-bold">
                  Experiment-Filters:
                </div>
                <ul class="text-body-1">
                  <li>Label: A free text field for the name of the experiment.</li>
                  <li>Community Context: A selection list with contexts.</li>
                </ul>
              </v-card-text>
            </v-card>

            <p class="text-body-1">
              Further overview tables for assigned objects (<strong>Events</strong>, <strong>Files</strong>, <strong>Links</strong>, <strong>Deliverables</strong>) can be accessed via the detailed view of the activities (see below).
            </p>

            <div class="text-h6">
              Detailed view
            </div>
            <p class="text-body-1">
              Selecting an activity in the overview tables directs you to the detailed view for the respective activity. Although the views differ for the three activity types (<strong>Expeditions</strong>, <strong>Numerical Simulations</strong>, <strong>Experiments</strong>), they follow a consistent structure:
            </p>

            <p class="text-body-1">
              At the top of the file view, tabs for the associated objects (<strong>Events</strong>, <strong>Files</strong>, <strong>Links</strong>, <strong>Deliverables</strong>, depending on what applies to the activity) are provided. Within these tabs, you will find an overview table for the respective objects. Some assigned objects such as Events offer a detailed view, accessible through these tables. The 'Info' tab on the far left shows details specific to the selected activity itself.
            </p>

            <p class="text-body-1">
              Buttons may appear at the bottom of the detailed view, depending on your personal permissions and login status, allowing for actions like editing or deleting the activity.
            </p>

            <div class="text-h6">
              My OSIS
            </div>
            <p class="text-body-1">
              Upon log in, you will be directed to the 'My OSIS' page which offers additional functionalities compared to the start page:
            </p>

            <p class="text-body-1">
              <strong>My Deliverables</strong>: This section presents a list of deliverables specifically assigned to you. Each deliverable is accessible directly from this section.
            </p>

            <p class="text-body-1">
              Similar to the start page, three tables are displayed, each corresponding to the type of scientific activity (<strong>Expeditions</strong>, <strong>Numerical Simulations</strong>, <strong>Experiments</strong>). However, on the My OSIS page, you have the ability to filter these tables by <strong>Community Context</strong>. The dropdown menu positioned above the tables conveniently lists all of your contexts. Simply select a context from the dropdown menu to filter the tables accordingly.
            </p>

            <p class="text-body-1">
              Lastly, the <strong>Latest files</strong> section lists the most recently changed files relevant for you. If no community context is selected above, files from all of your contexts are listed. However, if a context is selected, only context relevant files are displayed.
            </p>

            <p class="text-body-1">
              The My OSIS page os also accessible from the personal menue on the right-hand side of the OSIS header bar: <v-icon medium> {{$icons.mdiAccount}} </v-icon>
            </p>

            <div class="text-h6">
              Data models
            </div>

            <v-card color="osis-card">
              <v-card-text>
                <div class="text-h7 font-weight-bold">
                  Expedition
                </div>
                <Mermaid :graph="expeditionER" />

              </v-card-text>
            </v-card>

            <div class="text-h6">
              Personal rights
            </div>

            <p class="text-body-1">
              By default, <strong>anonymous users</strong> (i.e. users who are not logged in) can view activities which are open access only. These users cannot edit, delete or create any objects.
            </p>

            <p class="text-body-1">
              <strong>Logged-in users</strong> have some global rights. For example, they are allowed to create new scientific activities.
            </p>

            <p class="text-body-1">
              Logged-in users can also be assigned to <strong>global roles</strong>, which grants them privileges across OSIS. Such global roles are held by <strong>curators</strong> who are responsible for supporting OSIS users or maintaining data related to specific types of scientific activities on behalf of larger organizations. Global roles include <strong>Expedition Editor</strong>, <strong>Simulations Editor</strong>, and <strong>Experiment Editor</strong>. Additionally, there are also <strong>Administrators</strong> who are superusers tasked with troubleshooting and maintaining OSIS.
            </p>

            <p class="text-body-1">
              Despite global roles, access rights are determined by membership to a <strong>Community Context</strong>. Users who are members of a certain context have more privileges for activities belonging to that context than other users.
            </p>

            <p class="text-body-1">
              Finally, users may have privileges granted to them because of their <strong>personal relationship</strong> with the object in question. For example, if they are the <strong>creator</strong> of an object or the <strong>Principal Investigator/responsible person</strong> of a scientific activity.
            </p>

            <p class="text-body-1">
              The following table sums up the different rights granted by different roles. Scientific activities include <strong>Expeditions</strong>, <strong>Numerical Simulations</strong>, and <strong>Experiments</strong>. Associated objects include <strong>Events</strong>, <strong>Files</strong>, <strong>Links</strong>, <strong>Deliverables</strong>, and <strong>Port Calls</strong>.
            </p>
            <v-card>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Action</th>
                        <th class="text-left">User groups allowed to perform action</th>
                        <th class="text-left">Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, index) in permissionTable"
                        :key="index"
                      >
                        <td>{{ row.action }}</td>
                        <td>{{ row.groups }}</td>
                        <td>{{ row.comment }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>

          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
import apiRequests from '@/mixins/apiRequests'
import Mermaid from "@/components/Mermaid.vue";
import { version } from "../../package.json"

export default {
  name: 'DocuDialog',
  mixins: [apiRequests],
  components: { Mermaid },
  data() {
    return {
      show: true,
      frontendVersion: version,
      apiVersion: '',
      osisLogo: require('/public/images/osis_logo.svg'),
      permissionTable: [
        {
          action: "Create a scientific activity",
          groups: "Editors, Logged-in Users",
          comment: "Users can only assign community contexts to activities if they are a member of that community",
        },
        {
          action: "Edit a scientific activity",
          groups: "Editors¹, Context Members², Creators² ³, PI/ Responsible Person²",
          comment: "¹Type of global editor has to match activity type; ²Assignment of community requires membership there; ³Creator is the person who created the activity.",
        },
        {
          action: "Delete a scientific activity",
          groups: "Admins",
          comment: "To prevent accidental data loss, only admins are allowed to delete activities",
        },
        {
          action: "Create, Edit, Delete Associated Objects",
          groups: "Editors¹, Context Members², Creators³, PI/ Responsible Person³",
          comment: "¹Type of global editor has to match activity type of scientific object; ²Context of scientific object; ³Creator/ PI of scientific object",
        },
        {
          action: "Upload a New File Version",
          groups: "Editors, Context Members, Creators, PI/ Responsible Person, Uploader",
          comment: "Comments from previous row apply; Uploader only applicable to files",
        },
      ],
      expeditionER: `
        erDiagram
        Expedition {
        name text
            optional_label text
            chief_scientist Person
            vice_chief_scientist Person
            institution Affiliation "Organizing Institution"
            affiliation Affiliation "Chief Scientist's Affiliation"
            research_area text
            expocode text
            notes text
        }
        Location {
        }
        pc["Port Call"] {
        }
        Expedition ||--o{ pc : has
        pc }o--|| Location : has
        Person {
        }
        Expedition }o--|| Person : has

        Platform {
        }
        Expedition }o--|| Platform : has
        Event {
        }
        Expedition ||--o{ Event : has
        Event |o--o{ File : has
        Event |o--o{ dl : has

        dl["Data Link"] {
        }
        Expedition |o--o{ dl : has

        File {
        }
        Expedition |o--o{ File : has

        ru["Research Unit"] {
        }
        Expedition }o--o{ ru : has
        Project {
        }
        Expedition }o--o{ Project : has
        cc["Community Context"] {
        }
        Expedition }o--o{ cc : has
        Deliverable {
        }
        Expedition ||--o{ Deliverable : has
        `,
    }
  },
  watch: {
    show: function (newValue) {
      if (!newValue) {
        this.cancelDocu()
      }
    }
  },
  async mounted() {
    await this.getApiVersion()
  },
  methods: {
    cancelDocu() {
      this.$router.go(-1)
    },
    goToExpeditions() {
      this.$router.push({ name: 'Legs' })
    },
    goToSimulations() {
      this.$router.push({ name: 'Simulations' })
    },
    goToExperiments() {
      this.$router.push({ name: 'Experiments' })
    },
    async getApiVersion() {
      const requestParams = {
        method: "GET",
        url: 'version'
      }
      try {
        const res = await this.osisApiRequest(requestParams)
        if (res.status == 200) {
          this.apiVersion = await res.json()
        }
      }
      catch (error) {
        console.log(error)
      }
    },
  }
}
</script>

<style>
</style>
