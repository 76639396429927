<script>
import mermaid from 'mermaid';

mermaid.initialize({
  startOnLoad: false,
});

export default {
  name: "Mermaid",
  props: {
    graph: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.renderDiagram();
  },
  watch: {
    graph() {
      this.renderDiagram();
    },
  },
  methods: {
    async renderDiagram() {
      const graphDefinition = this.graph.trim();
      const { svg } = await mermaid.render('graphDiv', graphDefinition);
      this.$refs.mermaidContainer.innerHTML = svg;
    },
  },
}
</script>

<template>
  <div>
    <div ref="mermaidContainer" />
  </div>
</template>

